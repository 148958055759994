/* source : http://michalsnik.github.io/aos/ */

import $ from 'jquery';
import AOS from "../../node_modules/aos/dist/aos.js";



$(document).ready(function () {
	
	//box home
	$(".page-home div[class*='box-']").attr("data-aos","fade-up");
	$(".page-home div[class*='box-']").attr("data-aos-delay","500");
	$(".page-home div[class*='box-']").attr("data-aos-anchor-placement","top-bottom");
	 
	 
	$(".page-home .box-mobile .cb-item").attr("data-aos","fade-up");
	$(".page-home .box-mobile .cb-item").attr("data-aos-delay","500");
	$(".page-home .box-mobile .cb-item").attr("data-aos-anchor-placement","top-bottom"); 
 

   AOS.init({
   		duration: 1000,
      delay:1000,
      placement:"top-center",
      /*disable: function() {
		    var maxWidth = 1200;
		    return window.innerWidth < maxWidth;
  		}*/
    });
});